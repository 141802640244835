/*eslint-disable*/
import React from "react";
// reactstrap components
import {Col, Nav, NavItem, NavLink, Row} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer pl-3 pr-3 mt-2 pb-2">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="text-black-50 copyright text-center text-xl-left text-muted ">
              © {new Date().getFullYear()}{" "}
              <a
                  className="font-weight-bold ml-1 "
                  href="#"
                  target="_blank"
              >
                Máquina do Cartola
              </a>
            </div>
          </Col>
          <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              <NavItem>
                <NavLink
                    className="text-black-50"
                    href="mailto"
                    target="_blank"
                >
                  contato@maquinadocartola.com
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className="text-black-50"
                    href="/sobre"
                    target="_blank"
                >
                  Sobre
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
