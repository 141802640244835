import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
// core components

import Header from "components/Headers/Header.js";
import Badge from "reactstrap/lib/Badge";
import Datedown from "../components/Datedown";
import ResultPlayers from "../components/Dashboards/ResultPlayers";
import MostPlayers from "../components/Dashboards/MostPlayers";
import {connect} from "react-redux";
import {utils} from "../helpers";
import {Link} from "react-router-dom";

class Index extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data_fecha, rodada_id, num_times_cartola, num_times_maquina, destaques, logged, resultados} = this.props;
        return (
            <>
                <Header>
                    <Container fluid>
                        <div className="header-body">
                            <Row className="text-right justify-content">
                                <Col lg="12" md="12">
                                    <h1 className="text-white d-none d-md-block ">A Máquina do Cartola FC </h1>
                                    <p className="text-lead text-gray-dark">
                                        Uma ferramenta de apoio a decisão para escalar time no fantasy game Cartola FC
                                    </p>
                                </Col>
                            </Row>
                            {/* Card stats */}
                            <Row>
                                <Col>
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardHeader className="pb-0">
                                            <h1>Rodada {rodada_id}</h1>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col mb-4">
                                                    <Link to={"/selecao"}>
                                                        <button type={"button"} className={"btn btn-success"}>
                                                            Seleção da Rodada
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="col mb-4">
                                                    <Link to={"/olheiro"}>
                                                        <button type={"button"} className={"btn btn-info"}>
                                                            Olheiro
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="col mb-4">
                                                    <Link to={"/escalar"}>
                                                        <button type={"button"} className={"btn btn-primary"}>
                                                            Escalar
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="col mb-4">
                                                    <Link to={"/parcial"}>
                                                        <button type={"button"} className={"btn btn-primary"}>
                                                            Parciais
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="col mb-4">
                                                    <Link to={"/partidas"}>
                                                        <button type={"button"} className={"btn btn-primary"}>
                                                            Jogos
                                                        </button>
                                                    </Link>
                                                </div>
                                                {/*<div className="col mb-4">*/}
                                                {/*    <Link to={"/classificacao"}>*/}
                                                {/*        <button type={"button"} className={"btn btn-primary"}>*/}
                                                {/*            Times*/}
                                                {/*        </button>*/}
                                                {/*    </Link>*/}
                                                {/*</div>*/}

                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="card-stats mb-4  mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0">
                                                        Times Escalados na Máquina
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {utils.printInteger(num_times_maquina)}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-orange text-white rounded-circle shadow">
                                                        <i className="fa fa-futbol"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <br/>
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                        Times Escalados no Cartola
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                        {utils.printInteger(num_times_cartola)}
                                                      </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-default text-white rounded-circle shadow">
                                                        <i className="fas fa-futbol"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0">
                                                        Mercado fecha em
                                                    </CardTitle>
                                                    <h1 className="font-weight-bold mb-0">
                                                        <Badge color="" className="h1 badge-success">
                                                            <Datedown date_close={data_fecha}
                                                                      label_close="Mercado Fechado !"/>
                                                        </Badge>
                                                    </h1>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                        <i className="fas fa-clock"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <br/>
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <span className="h2 font-weight-bold mb-0">
                                                        <Link to={"/sobre#comentarios"}>
                                                            <button type={"button"} className={"btn btn-primary btn-lg"}>
                                                                Comentários
                                                            </button>
                                                        </Link>
                                                        {/*<div className="addthis_horizontal_follow_toolbox"></div>*/}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div
                                                        className="icon icon-shape bg-indigo text-white rounded-circle shadow">
                                                        <i className="ni ni-notification-70"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Header>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="6">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8">
                                            <h3 className="mb-0">Destaques da #Rodada {rodada_id}</h3>
                                        </div>
                                        <div className="col-4 text-right">
                                            <Link to={"/selecao"}>
                                                    <button type={"button"} className={"btn btn-primary btn-sm"}>
                                                        Ver mais
                                                    </button>
                                            </Link>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <MostPlayers max={5} />
                            </Card>
                        </Col>
                        <Col xl="6">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-8">
                                            <h3 className="mb-0">Resultados #Rodada {0 + rodada_id - 1}</h3>
                                        </div>
                                        <div className="col-4 text-right">
                                            <Link to={"/resultados"}>
                                                    <button type={"button"} className={"btn btn-primary btn-sm"}>
                                                        Ver mais
                                                    </button>
                                            </Link>
                                        </div>
                                    </Row>
                                </CardHeader>
                                    <ResultPlayers itens={resultados} />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {mercado} = state.loadMercado;
    const { logged, auth } = state.authentication;
    if (mercado) {
        return {
            logged,
            data_fecha: mercado.data_fecha,
            rodada_id: mercado.rodada.codigo,
            num_times_cartola: mercado.num_times,
            num_times_maquina: mercado.num_times_maq,
            destaques: mercado.destaques,
            resultados: mercado.resultados?mercado.resultados:[]
        }
    } else {
        return {
            data_fecha: '',
            rodada_id: 0,
            num_times_cartola: 0,
            num_times_maquina: 0,
            destaques: []
        };
    }
}

export default connect(mapStateToProps)(Index);
