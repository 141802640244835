import React from "react";
import {Col, Row} from "reactstrap";
import {utils} from "../helpers";


class ClubList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_clubs: [],
        }
    }

    componentDidMount() {
        this.fetchTrans();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected_clubs !== this.props.selected_clubs) {
            this.fetchTrans()
        }
    }

    handleChange = (e) => {
        if (this.props.times && this.props.times.length > 0) {
            for (let t of this.props.times) {
                this.refs['t' + t.id].checked = false;
            }
        }

        const id = parseInt(e.target.attributes['data-id'].value);
        let selectlist = this.state.selected_clubs;
        this.setState({selected_clubs: utils.addOrRemove(selectlist, id)}, this.selectState);
    };

    //
    //                         onChange={() => (console.log(''))}
    //                         checked={this.state.checkedItems.get("t" + time.id) ? this.state.checkedItems.get("t" + time.id) : false}
    fetchTrans() {
        const selected_clubs = [];
        if (this.props.times && this.props.times.length > 0) {
            for (let t of this.props.times) {
                this.refs['t' + t.id].checked = false;
            }
            if (this.props.selected_clubs && this.props.selected_clubs.length > 0) {
                for (let t of this.props.selected_clubs) {
                    selected_clubs.push(t);
                }
            }
            this.setState({selected_clubs: selected_clubs}, this.selectState);
        }

    }

    selectState() {

        if (this.state.selected_clubs && this.state.selected_clubs.length > 0) {
            for (let t of this.state.selected_clubs) {
                if(this.refs['t' + t]){
                    this.refs['t' + t].checked = true;
                }
            }
        }
        this.props.onChange(this.state.selected_clubs);

    }


    render() {
        const {times} = this.props;
        return (
            <Row className="form-check-inline">{
                times.map((time, index) => (
                    <Col xs="3" md="2" key={"time" + time.id} className="custom-control custom-checkbox m-2">
                        <input
                            className="custom-control-input"
                            ref={"t" + time.id}
                            id={"t" + time.id}
                            data-id={time.id}
                            type="checkbox"
                            name={"t" + time.id}
                            onClick={this.handleChange}
                        />
                        <label className="custom-control-label" htmlFor={"t" + time.id}>
                            <img
                                alt="..."
                                src={`assets/img/escudo/${time.slug}.png`}
                            />
                        </label>
                    </Col>
                ))
            }

            </Row>


        );
    }
};

export default ClubList;
