import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Col, Media, Progress, Row} from "reactstrap";
import {utils} from "../helpers";
import moment from "moment";
import 'moment/locale/pt-br'

const PartidaItem = props => {
    const { item, times } = props;
    let dataPartida = '';
    if(item){
        let dateToFormat = item.data + " "+ item.horario;
        moment.locale('pt-br')
        dataPartida = moment(dateToFormat).format('LLLL');
    }

    return (
        <Row key={'p' + props.id} className={item.eh_classico?"classico":""}>
                    <Col xs="12" sm="12" md="12">
                        <Row>
                            <Col xs="10" sm="11" md="11" className="text-center">
                                <Row>
                                    <Col xs="3" sm="3" md="3" className="text-left">
                                        <Progress
                                            max="100"
                                            value={utils.printInteger(item.score_casa)}
                                            barClassName={utils.printPercentLabel(item.score_casa)}>
                                            {utils.printInteger(item.score_casa)}%
                                        </Progress>
                                        <Badge className="bg-default text-white posicao">{times[item.time_casa] && times[item.time_casa].classificacao}º</Badge>
                                    </Col>
                                    <Col xs="2" sm="2" md="2" className="text-center">
                                        <div className="row glyphicon text-center">
                                            <img alt={times[item.time_casa].slug} src={`assets/img/escudo/${times[item.time_casa].slug}.png`} />
                                        </div>
                                        <div className="row text-center">
                                            <span className="uppercase">{times[item.time_casa].abreviacao} </span>
                                        </div>
                                    </Col>
                                    <Col xs="2" sm="2" md="2">
                                        <div className="row align-items-center">
                                            <table>
                                                <tr>
                                                    <td className={"text-center"}>
                                                        <b className="placar ">{item.gol_c!=null ? item.gol_c : "-"}</b>
                                                    </td>
                                                    <td className={"text-center"}>
                                                        <b>x</b>
                                                    </td>
                                                    <td className={"text-center"}>
                                                        <b className="placar ">{item.gol_v!=null ? item.gol_v : "-"}</b>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </Col>
                                    <Col xs="2" sm="2" md="2" className="text-center">
                                        <div className="row glyphicon text-center">
                                            <img alt={times[item.time_visitante].slug} src={`assets/img/escudo/${times[item.time_visitante].slug}.png`} />
                                        </div>
                                        <div className="row text-center">
                                            <span className="uppercase">{times[item.time_visitante].abreviacao} </span>
                                        </div>
                                    </Col>
                                    <Col xs="3" sm="3" md="3" className="text-right">
                                        <Progress
                                            max="100"
                                            value={utils.printInteger(item.score_visitante)}
                                            barClassName={utils.printPercentLabel(item.score_visitante)}>
                                            {utils.printInteger(item.score_visitante)}%
                                        </Progress>
                                        <Badge className="bg-default text-white small posicao">{times[item.time_visitante].classificacao}º</Badge>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="1" sm="1" md="1" className="text-right">
                                <Badge className="bg-primary text-white small posicao">{item.ordem}º</Badge>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="10" sm="10" md="10" className="text-center uppercase small">
                            <b>{item.local}</b> - {dataPartida}
                    </Col>
            </Row>
    );
};

PartidaItem.propTypes = {
    item: PropTypes.object.isRequired,
    times: PropTypes.array.isRequired,
};

export default PartidaItem;
